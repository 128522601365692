import { template as template_8b15f0eb920442daa4a40e32e00c2faa } from "@ember/template-compiler";
const FKText = template_8b15f0eb920442daa4a40e32e00c2faa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
