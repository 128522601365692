import { template as template_4907ac44ef24437287a583b731ca2c59 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4907ac44ef24437287a583b731ca2c59(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
