import { template as template_7b0d4e67331848718cd9af2145e1be65 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_7b0d4e67331848718cd9af2145e1be65(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
